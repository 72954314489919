import { track } from '../util/analytics-util';
import { isAndroid, isiOS } from '../util/platform-util';

(() => {
  if (isiOS()) {
    track('QR Code Scanned', { destination: 'iOS' });
    window.location.href = 'https://apps.apple.com/us/app/here-talk-text-watch/id1541854332';
    return;
  }

  if (isAndroid()) {
    track('QR Code Scanned', { destination: 'Android' });
    window.location.href = 'https://play.google.com/store/apps/details?id=fm.here.android';
    return;
  }

  window.location.href = 'https://here.fm';
})();
